/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { graphql } from 'gatsby'
import { Center } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../layout/names'
import SEO from '../../components/SEO/PageSEO'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'

const Page = ({ location, data }) => (
  <Layout
    names={data.namesJson}
    headerText="Generador de nombres y apellidos de personas al azar"
  >
    <SEO
      title="Generador de nombres aleatorios"
      titleSeo="Generador de nombres aleatorios"
      description="Herramienta para generar nombres al azar con apellidos, para hombres, mujeres y bebés en español."
      image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
      // lang="es"
      datePublished="2021-05-11T12:29:33-03:00"
      dateModified="2021-05-11T12:29:33-03:00"
      // breadcrumbs={[{ name: 'Nombres', url: '/nombres/' }]}
      permalink={location.pathname}
      // breadcrumbList={[]}
      // noindex={false}
    />
    <Heading1>Generador de nombres aleatorios</Heading1>
    <Paragraph>
      Si por alg&uacute;n motivo necesitas generar nombres al azar, esta
      herramienta online te ayudar&aacute; con esa tarea. De forma simple y
      r&aacute;pida podr&aacute;s generar uno o m&aacute;s nombres de forma
      aleatoria, filtrando por distintas caracter&iacute;sticas como sexo,
      nacionalidad, idioma, etc.
    </Paragraph>
    <Paragraph>
      Este generador de nombres y apellidos aleatorios te permitir&aacute;
      generar en instantes decenas de nombres y apellidos con los criterios que
      hayas escogido, sin l&iacute;mite de uso, con una amplia base de datos de
      nombres y apellidos correctamente caracterizados, y con una buena
      experiencia de usuario sin importar el dispositivo desde el cual lo
      est&eacute;s utilizando.
    </Paragraph>
    <Center my={5}>
      <StaticImage src="https://placekitten.com/800/600" alt="A kitten" />
    </Center>
    <Paragraph>
      Entre todas las caracter&iacute;sticas que ofrece esta herramienta para
      genera nombres aleatorios, las opciones m&aacute;s populares son la de
      generar nombres espec&iacute;ficamente para ni&ntilde;os/hombres o para
      ni&ntilde;as/mujeres, pero tambi&eacute;n destacan los filtros de
      apellidos populares seg&uacute;n el pa&iacute;s para obtener nombres al
      azar comunes entre los habitantes de una localidad espec&iacute;fica.
    </Paragraph>
    <Heading2>
      &iquest;Con qu&eacute; motivo podr&iacute;a alguien necesitar un generador
      de nombres y apellidos al azar?
    </Heading2>
    <Paragraph>
      Hay muchos motivos por los que una persona podr&iacute;a necesitar una
      herramienta que le brinde nombres al azar en lugar de tener que recorrer
      una lista de forma manual.
    </Paragraph>
    <Paragraph>
      Es tan simple y vers&aacute;til que resulta sencillo aplicar este
      generador aleatorio online a muchas situaciones. Vamos a explorar algunos
      a modo de ejemplo, pero si&eacute;ntete libre de usarlo para lo que
      m&aacute;s gustes, &iexcl;no dudes en comentarnos casos de usos curiosos
      en la secci&oacute;n de comentarios!
    </Paragraph>
    <Heading3>
      Futuros padres buscando un generador de nombres para beb&eacute;s
    </Heading3>
    <Paragraph>
      Un posible caso de uso es el de futuros padres que no logran decidirse
      respecto a qu&eacute; nombre escoger para su beb&eacute; y ya no tienen
      ganan de leer largas listas de nombres comunes para beb&eacute;s. Resulta
      m&aacute;s entretenido ir analizando un nombre a la vez, considerando cada
      posibilidad de forma aislada sin al mismo tiempo estar leyendo otras diez,
      adem&aacute;s de que este generador de nombres aleatorios no se limita a
      unos pocos nombres y apellidos populares, sino que tambi&eacute;n incluye
      algunas opciones mucho m&aacute;s raras e infrecuentes que no suelen
      aparecen en las listas que uno se encuentra en internet.
    </Paragraph>
    <Paragraph>
      En lugar de recorrer el ranking de los nombres de beb&eacute;s populares
      en *A&Ntilde;O*, en ocasiones es mucho m&aacute;s c&oacute;modo
      simplemente enfrentarse a una opci&oacute;n en particular y tomarse el
      tiempo que sea necesario para considerarlo, y luego pasar a la siguiente,
      sin necesariamente ir en ning&uacute;n orden en particular.
    </Paragraph>
    <Heading3>Creaci&oacute;n de cuentas con datos falsos o ficticios</Heading3>
    <Paragraph>
      Muchas personas llegan a esta herramienta luego de buscar un generador de
      nombres falsos al azar o, en otras palabras, nombres reales al azar que
      pueden ser utilizados para completar formularios donde uno no quiero poner
      datos espec&iacute;ficos a una persona real.
    </Paragraph>
    <Paragraph>
      Con tal de hacerlo m&aacute;s r&aacute;pido y c&oacute;modo, cuando la
      gente necesita inventarse un nombre resulta mejor el utilizar un generador
      de nombres falsos online dado que es realmente aleatorio y elige entre
      miles de nombres y apellidos disponibles, por lo que no se limita a los
      pocos nombres que uno conoce.
    </Paragraph>
    <Paragraph>
      Cuando uno intenta imaginar un nombre de persona, por lo general recurre a
      personas que uno conoce o escucha nombrar a menudo, por lo que eso no
      resulta realmente aleatorio ni variado. Ya ni hablar de si uno necesita,
      por ejemplo, rellenar una plantilla de Excel con datos de 100 personas,
      usuarios o clientes ficticios, indudablemente uno terminar&aacute;
      perdiendo mucho tiempo intentando dar con 100 nombres diferentes.
    </Paragraph>
    <Heading4>
      Evita dar tus datos reales en sitios de dudosa reputaci&oacute;n
    </Heading4>
    <Paragraph>
      Otro ejemplo para el uso de un generador de nombres de personas falsas se
      presenta cuando estamos navegando alg&uacute;n sitio web y queremos
      suscribirnos a las futuras publicaciones utilizando nuestro correo
      electr&oacute;nico, pero sin dar ning&uacute;n otro dato, por lo que
      generar un nombre real y com&uacute;n nos puede ahorrar tiempo.
    </Paragraph>
    <Paragraph>
      Muy similar al ejemplo anterior, hoy en d&iacute;a cualquier
      aplicaci&oacute;n o sitio web puede pedir que nos registremos para hacer
      uso de funciones b&aacute;sicas y al crear una cuenta se nos piden un
      mont&oacute;n de datos personales que quedar&aacute;n guardados en un
      servidor que puede no llegar a ser confiable. Ante esta situaci&oacute;n,
      podemos utilizar este generador de nombres falsos para crear una cuenta,
      registrarnos en foros y aplicaciones, y m&aacute;s sin necesidad de poner
      palabras aleatorias sin sentido, pero tampoco dar nuestros datos reales de
      forma innecesaria a un sitio cuya reputaci&oacute;n no es lo
      suficientemente buena como para fiarnos de qu&eacute; tan bien
      proteger&aacute; nuestra informaci&oacute;n.
    </Paragraph>
    <Paragraph>
      En este sitio web no promovemos el dar datos falsos en formularios donde
      se requiera veracidad, sino que damos una soluci&oacute;n a los infinitos
      sitios webs y aplicaciones que nos piden nuestros datos personales sin
      necesidad ni justificaci&oacute;n alguna. Siempre que as&iacute; se
      requiera, sobre todo en documentos con relevancia a nivel legal (como
      cuentas bancarias o tr&aacute;mites gubernamentales), aconsejamos el uso
      de datos correctos y verdaderos.
    </Paragraph>
    <Heading3>
      Para dar vida a personajes en historias, partidas de rol y relatos que
      escribamos
    </Heading3>
    <Paragraph>
      Otra interesante opci&oacute;n de uso para esta herramienta online es la
      de generar nombres de personas al azar para asign&aacute;rselos a
      personajes en cualquier tipo de relato o historia, incluso en partidas de
      rol de mesa o en videojuegos.
    </Paragraph>
    <Paragraph>
      Hacer uso de este generador de nombres de personas aleatorios es
      f&aacute;cil y r&aacute;pido, adem&aacute;s de estar basado en una lista
      de nombres utilizados por muchas personas alrededor del mundo, por lo que
      no hay raz&oacute;n para perder el tiempo intentando imaginarnos, tienes
      miles a tu disposici&oacute;n aqu&iacute; mismo al alcance de un clic o un
      toque.
    </Paragraph>
    <Paragraph>
      En este generador encontrar&aacute;s nombres de personas al azar reales y
      con filtros para todas las caracter&iacute;sticas que puedas necesitar,
      puedes escoger el sexo, la nacionalidad, el idioma, la cantidad y
      m&aacute;s, no dejes que la elecci&oacute;n de nombres para tus personajes
      se vuelva una p&eacute;rdida de tiempo cuando puedes directamente utilizar
      este generador aleatorio y centrarse en las actividades m&aacute;s
      relevantes, que despu&eacute;s de todo, es muy probable que lo importante
      sean las aventuras y desdichas de tus personajes, no sus nombres.
    </Paragraph>
    <Paragraph>
      Y si resulta que los nombres de personas que necesitas generar de forma
      aleatoria s&iacute; que son importantes, no te preocupes, aqu&iacute;
      podr&aacute;s ajustarlos exactamente a las caracter&iacute;sticas que
      puedas necesitar.
    </Paragraph>
    <Paragraph>
      &iquest;Se te ocurren m&aacute;s casos de usos para este generador de
      nombres aleatorios online? &iquest;En qu&eacute; actividades o situaciones
      te ha resultado &uacute;til generar nombres de personas al azar?
      Cu&eacute;ntanoslos en los comentarios, al final de la p&aacute;gina.
    </Paragraph>
  </Layout>
)

export default Page

export const pageQuery = graphql`
  query {
    namesJson {
      surnames
      female
      male
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
